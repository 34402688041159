<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 11L11 1" stroke="currentColor" />
    <path d="M11 11L1 1" stroke="currentColor" />
  </svg>
</template>
